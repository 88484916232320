import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import { Card, CardContent, createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import { scrollTo } from "../var/util"
import Footer from "../components/partials/Footer"
import Typography from "@material-ui/core/Typography"
import Meta from "../components/Meta"
import DownloadSimple from "../components/partials/DownloadSimple"

const useStyles = makeStyles((t) => createStyles({
  main: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  scroll: {
    position: "fixed",
    bottom: t.spacing(2),
    right: t.spacing(2),
  },
  toolbar: {
    ...t.mixins.toolbar,
    background: theme.custom.fancyBackground,
  },
  content: {
    flex: 1,
  },
  card: {
    marginTop: "20vh",
    marginBottom: theme.spacing(3),
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const IndexPage = () => {
  const classes = useStyles()
  // const prefersDarkMode = 0//useMediaQuery("(prefers-color-scheme: dark)")
  // const appTheme = React.useMemo(
  //   () =>
  //     prefersDarkMode ? darkTheme : theme,
  //   [prefersDarkMode],
  // )
  return (
    <React.Fragment>
      <Meta title={"Page not found"} />
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Memo Reminders"/>
            <section id={"content"} className={classes.content}>
              <div className={classes.toolbar}/>
              <Container maxWidth={"md"}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>404 - Page not found</Typography>
                    <Typography align={"center"} variant={"body1"}>The page you requested could not be
                      found. </Typography>
                  </CardContent>
                </Card>
                <Container>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography align={"center"} variant={"h6"}>Get it now</Typography>
                      <DownloadSimple/>
                    </CardContent>
                  </Card>
                </Container>
              </Container>
            </section>
            <Footer excludeSupport={true}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </NoSsr>
      </React.Fragment>
  )
}

export default IndexPage
